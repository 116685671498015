import React from 'react';

import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CdnImage from '~/shared/components/CdnImage';
import { Button, desktop, mobile } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  background-color: #fce063;
  ${mobile`
    text-align: center;
  `}
`;

const ErrorPageContent = styled.section`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textLight};
  text-shadow: 0px 0px 10px
    ${({ theme }) => transparentize(0.9, theme.colors.palette.black)};
  position: absolute;
  ${desktop`
    top: 50%;
    right: 12.5%;
    transform: translateY(-50%);
    max-width: 20%;
  `}
  ${mobile`
    font-size: 4vw;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
  `}
`;
const BlockImage = styled(CdnImage)`
  display: block;
  margin-right: auto;
  margin-left: auto;
`;
const MobileImage = styled(BlockImage)`
  ${desktop`display:none`}
`;
const DesktopImage = styled(BlockImage)`
  ${mobile`display:none`}
`;
const ErrorHeading = styled.h1`
  font-size: 3em;
`;
const ErrorSubHeading = styled.h2`
  font-size: 1.25em;
  margin-bottom: ${({ theme }) => theme.space('m')};
`;
const ErrorCopy = styled.p`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.space('m')};
`;
const KeepShoppingButton = styled(Button)`
  font-size: 1em;
  font-weight: bold;
  width: auto;
`;

export default function NotFound({ className }) {
  return (
    <Wrapper className={className}>
      <MobileImage path="site/404/404-mobile.jpg" alt="Not Found" />
      <DesktopImage
        path="site/404/404-bg-image-1440x800-021119.jpg"
        alt="Not Found"
      />
      <ErrorPageContent>
        <header>
          <ErrorHeading>
            <FormattedMessage defaultMessage="Whoops" id="error_404.title" />
          </ErrorHeading>
          <ErrorSubHeading>
            <FormattedMessage
              id="error_404.sub_title"
              defaultMessage="We're having a ruff day."
            />
          </ErrorSubHeading>
        </header>
        <ErrorCopy>
          <FormattedMessage
            id="error_404.paragraph_1"
            defaultMessage="Sorry, we couldn&rsquo;t find what you were looking for."
          />
        </ErrorCopy>
        <ErrorCopy>
          <FormattedMessage
            id="error_404.paragraph_2"
            defaultMessage="Use your browser&rsquo;s &rsquo;Back&rsquo; button to return to the previous page."
          />
        </ErrorCopy>
        <KeepShoppingButton href="/" variant="darkBlue">
          <FormattedMessage
            id="error_404.contine"
            defaultMessage="Or Keep Shopping"
          />
        </KeepShoppingButton>
      </ErrorPageContent>
    </Wrapper>
  );
}
NotFound.propTypes = {
  className: PropTypes.string,
};
