import React from 'react';

import PropTypes from 'prop-types';

import useCdn from '../../utils/useCdn';

export default function CdnImage({ path, localize, alt, ...props }) {
  const { getCdnImagePath } = useCdn();
  const src = getCdnImagePath(path, localize);
  return <img src={src} {...props} alt={alt} />;
}
CdnImage.propTypes = {
  alt: PropTypes.string.isRequired,
  localize: PropTypes.any,
  path: PropTypes.string.isRequired,
};
