import React from 'react';

import config from 'config';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { isLocale } from '~/shared/utils/helpers';
import useLocalizedConfig from '~/shared/utils/useLocalizedConfig';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { useIntl, defineMessages } from '~/techstyle-shared/react-intl';
import {
  useCookies,
  useDomain,
  useSession,
} from '~/techstyle-shared/redux-core';

const dynamicDefineMessages = defineMessages;
const defaults = dynamicDefineMessages({
  title: {
    id: 'site_seo_title.default',
    defaultMessage: config.get('public.brand.label'),
  },
  description: {
    id: 'site_seo_description.default',
    defaultMessage: config.get('public.brand.label'),
  },
});

const brand = config.get('public.brand.name');

const GSV_PATH = 'public.google.siteVerification';
const appleAppId = config.get('public.apple.appId');
const appleAppStoreUri = config.get('public.apple.appStoreUri');

export default function DefaultHead({ description, noIndex, title }) {
  const intl = useIntl();
  const verificationKey = useLocalizedConfig(GSV_PATH);

  const { tld } = useDomain();
  const smartAppBannerLocale = isLocale(['US', 'UK', 'DE', 'ES', 'FR'], tld);
  const { isLoggedIn } = useSession();
  const { isVip } = useMembership();
  const displaySmartAppBanner = smartAppBannerLocale && isLoggedIn && isVip;

  // BlueGreen POC favicon
  const [cookies] = useCookies();
  let favicon = 'favicon.ico';
  if (cookies.canary !== undefined || cookies.color !== undefined) {
    favicon = `favicon_ENV.ico?v=${Date.now()}`;
  }

  // default used for empty objects or null values
  try {
    if (!title) {
      title = intl.formatMessage(defaults.title);
    } else if (typeof title !== 'string') {
      title = intl.formatMessage(title) || intl.formatMessage(defaults.title);
    }
  } catch (e) {
    title = intl.formatMessage(defaults.title);
  }

  // default used for empty objects or null values
  try {
    if (!description) {
      description = intl.formatMessage(defaults.description);
    } else if (typeof description !== 'string') {
      description =
        intl.formatMessage(description) ||
        intl.formatMessage(defaults.description);
    }
  } catch (e) {
    description = intl.formatMessage(defaults.description);
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {noIndex && <meta name="robots" content="noindex" />}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/static/favicon/${brand}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/static/favicon/${brand}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/static/favicon/${brand}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/static/favicon/${brand}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`/static/favicon/${brand}/safari-pinned-tab.svg`}
        color="#1B1C19"
      />
      <link rel="shortcut icon" href={`/static/favicon/${brand}/${favicon}`} />

      <meta name="apple-mobile-web-app-title" content={brand} />
      <meta name="application-name" content={brand} />
      <meta name="msapplication-TileColor" content="#1B1C19" />
      {displaySmartAppBanner && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${appleAppId}, app-argument=${appleAppStoreUri}`}
        />
      )}
      <meta
        name="msapplication-config"
        content={`/static/favicon/${brand}/browserconfig.xml`}
      />
      <meta name="theme-color" content="#ffffff" />

      {verificationKey && (
        <meta name="google-site-verification" content={verificationKey} />
      )}
    </Head>
  );
}

DefaultHead.propTypes = {
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  noIndex: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
