import config from 'config';

import { useIntl } from '~/techstyle-shared/react-intl';

const cdnBaseUrl = config.get('public.cdn');

export default function useCdn() {
  const intl = useIntl();

  const getCdnRootPath = (path) => {
    return `${cdnBaseUrl}/${path}`;
  };

  const getCdnImagePath = (path, localize = 'global') => {
    if (localize === true) {
      localize = intl.locale.replace('-', '_');
    }

    return getCdnRootPath(
      `media/images/${localize.length ? localize + '/' : ''}${path}`
    );
  };

  return {
    cdnBaseUrl,
    getCdnImagePath,
    getCdnRootPath,
  };
}
