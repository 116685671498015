import { useEffect, useState } from 'react';

import config from 'config';

import { useDomain } from '~/techstyle-shared/redux-core';

export default function useLocalizedConfig(
  configPath,
  options = { tld: null }
) {
  const [key, setKey] = useState('');
  const domain = useDomain();
  const tld = options.tld ?? domain.tld;
  const localizedConfig = config.get(configPath);

  useEffect(() => {
    if (localizedConfig) {
      setKey(localizedConfig[tld]);
    }
  }, [tld, localizedConfig]);

  return key;
}
