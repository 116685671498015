import React from 'react';

import styled from 'styled-components';

import DefaultHead from '~/layout/components/DefaultHead';
import NotFound from '~/staticPages/components/NotFound';

const Wrapper = styled.div``;

export default function NotFoundPage() {
  return (
    <Wrapper>
      <DefaultHead />
      <NotFound />
    </Wrapper>
  );
}
